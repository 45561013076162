import store from "@/store/index"

/**
 * 校验邮箱
 * @param emailStr{String}
 * @returns {boolean}
 */
export const checkEmail = (emailStr) => /^[a-z0-9]{1}[a-z0-9_-]{1,}@[a-z0-9]{1,}(\.[a-z]{2,})*\.[a-z]{2,}$/.test(emailStr)
/**
 * 校验密码及强度约束
 * @param password{String}
 * @returns {boolean}
 */
export const checkPassword = (password) => /(?=^.{8,16}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).*$/.test(password)
/**
 * 校验手机号
 * @param phoneNum{String}
 * @returns {boolean}
 */
export const checkPhone = (phoneNum) => /^1[345678]{1}\d{9}$/.test(phoneNum)

export function setregister(account) {
    try {
       
        window.TDAPP.register(account)
        //   return true
        setlogin(account)
    } catch (err) {
      
        return false
    }
}

export function setlogin(account) {
    try {
       
        window.TDAPP.login(account)
        return true
    } catch (err) {
       
        setregister(account)
        //   return false
    }
}

const extractTheme = (colorList) => {
    const theme = {};
    colorList.forEach(item => {
       
        let camelCaseKey = camelCase(item.key);
        if (item.key && item[camelCaseKey]) {
            theme[item.key] = item[camelCaseKey];
        }
    });
    return theme;
};

// 更新主题相关内容
export function updateTopic(t) {
    let topic = ''
    if (t) {
        topic = t
    } else {
        topic = sessionStorage.getItem('topic') ? JSON.parse(sessionStorage.getItem('topic')) : null
    }
    if (!topic) {
        document.documentElement.removeAttribute('style');
        document.documentElement.style.setProperty(`--nav-bg-color`, '#fff');
        document.documentElement.style.setProperty(`--el-color-primary`, '#0938F7');
        return
    }
    let colorList = JSON.parse(topic.topicColor)
    if (topic.navigationViewType === '1') {
        // 1横向
        colorList = colorList.filter(item => item.type !== 'vertical')
    }
    if (topic.navigationViewType === '2') {
        // 2纵向
        colorList = colorList.filter(item => item.type !== 'horizontal')
    }
    setTopic(colorList)
}

const setTopic = (colorList) => {
    let theme = extractTheme(colorList);

    // 将主题样式对象转换为 CSS 自定义属性
    Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
    let primaryColor = theme['primary-color']
    if (primaryColor) {
        handleThemeStyle(primaryColor)
    }
    /* 判断 导航是不是渐变色 注意： 这里只有导航是渐变的。渐变色新增 一级 二级选中菜单。 新增 tab标签选中背景颜色  */
    let gradientList = ['nav-bg-color', 'el-menu-bg2-active-color', 'el-menu-active-bg-color', 'el-tabs-active-color']
    gradientList.forEach(item => {
        let navBg = colorList.filter(i => i.key === item)[0]
        let camelCaseKey = camelCase(item);
        if (navBg) {
            if (navBg[camelCaseKey]) {
                document.documentElement.style.setProperty(`--${item}`, navBg[camelCaseKey]);
            }
            if (navBg[camelCaseKey + 'Gradient']) {
                document.documentElement.style.setProperty(`--${item}`, navBg[camelCaseKey + 'Gradient']);
            }
        }

    })

    // 更新配置的echarts 的颜色信息 返回一个数组
    let echartsColor = colorList.filter(item => item.key.indexOf('echarts') > -1)
    let echartsColorList = echartsColor.map(item => item[item.key])
    if (echartsColorList && echartsColorList.length) {
        sessionStorage.setItem("echartsColor", JSON.stringify(echartsColorList))
    } else {
        sessionStorage.setItem("echartsColor", JSON.stringify(["#69B9FE", "#AF87FE", "#9CE0FF", "#FFA897", "#919FFF", "#CC6FC3", "#2CABBB", "#F47A75", "#008EE9", "#C2A160"]))
    }
}

// 处理主题样式
export function handleThemeStyle(theme) {
    document.documentElement.style.setProperty('--el-color-primary', theme)
    for (let i = 1; i <= 9; i++) {
        document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, `${getLightColor(theme, i / 10)}`)
    }
    for (let i = 1; i <= 9; i++) {
        document.documentElement.style.setProperty(`--el-color-primary-dark-${i}`, `${getDarkColor(theme, i / 10)}`)
    }
}

// 主题颜色大驼峰转换
export function camelCase(str) {
    return str.replace(/-([a-z])/g, (match, letter) => {
        return letter.toUpperCase();
    })
}

// 变浅颜色值
export function getLightColor(color, level) {
    let rgb = hexToRgb(color)
    for (let i = 0; i < 3; i++) {
        rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i])
    }
    return rgbToHex(rgb[0], rgb[1], rgb[2])
}

// 变深颜色值
export function getDarkColor(color, level) {
    let rgb = hexToRgb(color)
    for (let i = 0; i < 3; i++) {
        rgb[i] = Math.floor(rgb[i] * (1 - level))
    }
    return rgbToHex(rgb[0], rgb[1], rgb[2])
}

// hex颜色转rgb颜色
export function hexToRgb(str) {
    str = str.replace('#', '')
    let hexs = str.match(/../g)
    for (let i = 0; i < 3; i++) {
        hexs[i] = parseInt(hexs[i], 16)
    }
    return hexs
}

// rgb颜色转Hex颜色
export function rgbToHex(r, g, b) {
    let hexs = [r.toString(16), g.toString(16), b.toString(16)]
    for (let i = 0; i < 3; i++) {
        if (hexs[i].length == 1) {
            hexs[i] = `0${hexs[i]}`
        }
    }
    return `#${hexs.join('')}`
}


export function removeLocaStorage() {
    sessionStorage.removeItem('appid')
    sessionStorage.removeItem('createCalculationForm');
    sessionStorage.removeItem('createCalculationTable');
    sessionStorage.removeItem('orgFullName');
    sessionStorage.removeItem('logoPhoto');
    sessionStorage.removeItem('userPath');
    sessionStorage.removeItem('menuList');
    sessionStorage.removeItem('routerList');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('imgUrl');
    sessionStorage.removeItem('editableTabs');
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('appRow')
    sessionStorage.removeItem('appName')
    sessionStorage.removeItem('appNameEnName')
    sessionStorage.removeItem('chooseTenant')
    sessionStorage.removeItem('chooseTenantName')
    sessionStorage.removeItem('imgUrl');
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('ORGINFO');
    sessionStorage.removeItem('topic');
    sessionStorage.removeItem('whiteMenu');
    store.commit("SET_ORGINFO", {})
    store.commit("SET_USERMANAGE", {})
    store.commit("SET_FUNLIST", [])
    store.commit("SET_APPINFO", {})
    store.commit("setEditableTabs", [])
}
