<template>
  <div class="home-main">
    <Nav
      :is-menu="false"
      :userInfo="userInfo"
      :shortcutKeyList="shortcutKeyList"
      menuList="[]"
      :isLogin="isLoginState"
      :isHomeShow="true"
      defaultMenuGroup=""
      defaultMenuId=""
      ref="navRef"
    ></Nav>
    <div class="layout flex" :style="{ height: 'calc(100% - 67px)' }">
      <div class="layout-main" :class="'pt-20'" :style="{ width: ' 100%' }">
        <div
          class="home_right w-full h-full bg-white relative flex justify-between"
          style="height: calc(100%)"
        >
          <div class="home-left  h-full border-card">
            <informationList
              title="热门资讯"
              ref="informationListRef"
              :isAll="false"
              :showTabs="false"
              :isCollect="true"
              :isHome="true"
            ></informationList>
          </div>
          <div class="home-right  h-full">
            <productList
              title="产品一览"
              ref="productListRef"
              :isAll="false"
              :showTabs="false"
            ></productList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/utils/registerMicroAppsConfig";
import { getUserInfoByToken } from "@/api/UserInfo";
import { mapMutations, mapState } from "vuex";
import { Search, DArrowLeft, DArrowRight } from "@element-plus/icons-vue";
import {
  checkToken,
} from "@/api";
import comAbout from "@/views/About.vue";
import { appsData, dynamicRegisterApps } from "@/utils/registerMicroAppsConfig";
import Nav from "@/components/Nav.vue";
import informationList from "@/views/User/information.vue";
import productList from "@/views/User/productList.vue";

export default {
  name: "homePage",
  components: {
    informationList,
    productList,
    Nav,
  },
  computed: {
    ...mapState(["userInfo", "appInfo"]),
    // 定义 SearchIcon 变量为 Search 图标组件
    SearchIcon() {
      return Search;
    },
    DArrowLeftIcon() {
      return DArrowLeft;
    },
    DArrowRightIcon() {
      return DArrowRight;
    },
  },
  props: {},
  created() {},
  // 其他配置...
  data() {
    return {
      isShowMack: false,
      intervalId: null,
      menuIndex: 0,
      userInfo: {},
      shortcutKeyList: [], // 快捷功能列表
      isLoginState: false, //  token是否有效
      isMain: true, //  是否在主应用
      isView: false, //  是否是预览页
      pathname: "",
      breadcrumbData: "", // 面包屑导航
      isCollapse: true,
      menuValue: "",
      zhuti: null,
      defaultMenuId: "",
      defaultMenuGroup: [],
      userAvatar: "",
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      appList: [],
      menuList: [], // 检索出来的菜单功能
      menuListAll: [], // 全部菜单功能
      isHomeState: true,
    };
  },
  mounted() {
    this.$refs.informationListRef.getHomePage();
    this.$refs.productListRef.getList();
    this.checkTokenApi();
  },
  methods: {
    // 校验token是否有效
    checkTokenApi() {
      let token   = sessionStorage.getItem("token");
      if (!token) {
        this.isLoginState = false;
      }else{
        checkToken({token:JSON.parse(token)}).then(res=>{
          if(res.code == 200 && res.data){
            this.isLoginState = true;
          }else{
            this.isLoginState = false;
            sessionStorage.removeItem("token");
          }
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/css/common.less";

::v-deep(.searchIcon) {
  .el-icon {
    color: var(--el-menu-search-icon-color) !important;
  }
}

.home-main {
  height: 100vh;
  width: 100vw;
  background: var(--page-bg);
  .home-right {
    margin-left: 20px;
    width: 490px;
  }
  .menu-line {
    border-bottom: 1px solid var(--el-menu-line-color);
  }
  .home-left{
    width: calc(100% - 510px);
  }

  .layout {
    ::v-deep(.collapseBtn) {
      color: var(--el-menu-search-arrow-icon-color);
    }

    ::v-deep(.collapseBtn:hover) {
      background: var(--el-menu-search-arrow-hover-bg-color) !important;
      color: var(--el-menu-search-arrow-icon-hover-color) !important;
      border-color: transparent !important;

      .el-icon {
        color: var(--el-menu-search-arrow-icon-hover-color) !important;
      }
    }

    ::v-deep(.collapseBtn.el-button, .collapseBtn.el-button) {
      color: var(--el-menu-search-arrow-icon-color);
      border-color: transparent !important;
      background-color: var(--el-menu-search-arrow-bg-color);
      outline: 0;
      margin-left: 0.375rem;
    }

    .searchIcon {
      ::v-deep(.el-input__prefix) {
        color: var(--el-menu-search-arrow-icon-color);
      }

      ::v-deep(input::-webkit-input-placeholder) {
        -webkit-text-fill-color: var(--el-menu-search-place-color);
      }

      ::v-deep(.el-input__wrapper) {
        .el-input__inner {
          color: var(--el-menu-search-active-text-color) !important;
          border: none;
          background: none !important;
          height: 32px;
        }

        background-color: var(--el-menu-search-active-bg-color);
      }

      ::v-deep(.el-input__wrapper.is-focus) {
        .el-input__inner {
          color: var(--el-menu-search-active-border-color) !important;
        }

        background-color: var(--el-menu-search-active-bg-color);
        border: 1px solid var(--el-menu-search-active-border-color);
      }

      ::v-deep(.el-input__wrapper) {
        background-color: var(--el-menu-search-bg-color);
        box-shadow: 0 0 0 1px
          var(--el-menu-search-border-color, var(--el-menu-search-border-color))
          inset;
      }
    }
  }

  .danwei {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: left;
    font-style: normal;
    text-transform: none;

    .line {
      width: 1px;
      height: 30px;
      background: @border-color;
    }
  }

  .shortcut {
    display: inline-block;
    height: 20px;
    margin-right: 20px;
  }

  .p_box {
    .p1 {
      color: @text-color;
      font-size: 14px;
    }

    .p2 {
      color: @text-color;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei;
    }

    .p3 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
    }

    .p4 {
      font-family: Microsoft YaHei, Microsoft YaHei, serif;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
  }

  .tips {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #ff0000;
    border-radius: 50%;
  }
}

.bg-white {
  background: #fff;
}

.layout-main {
  background: var(--page-bg);
  padding: 0 20px;
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.menu-shadow {
  //box-shadow: 0px 0px 6px 1px rgba(3, 75, 143, 0.1608);
  border-right: 1px solid var(--menu-right);
}
</style>

<style>
.classTestwyc .el-dropdown__list {
  height: calc(100%);
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
  margin: 0;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 3px;
  padding-top: 3px;
}

.span-primary {
  color: var(--el-color-primary);
}

.span-text {
  color: #8c8c8c;
}
</style>
