<template>
  <div class="informationList">
    <!-- <el-scrollbar height='100%'> -->
    <div class="right bg-white" :class="props.isAll ? 'right1' : 'w-full'">
      <div v-if="list.length" :class="props.isAll ? 'list_bottom' : 'h-list_bottom'">
        <div class="list">
          <el-scrollbar ref="scrollbarRef" height="100%">
            <div class="list_item cursor-pointer" v-for="(item, index) in list" :key="index+'p'" @click.native="jumpTo(item)">
              <div class="flex flex-col relative border-card list_item-box "
              :class="item.id === props.productId?'list-item-box-active':''"
              >
                <div class="list_img  w-full   border" >
                  <el-carousel  :interval="interval"  class="h-full w-full"
                     :ref="el => setCarouselsRef(el, index)"
                   @change="(curr, prev) => handleChange(curr, prev, index)" 
                    :autoplay="false"
                    :loop="false"
                    before-leave="handleBeforeLeave"
                    v-if="item.productAttaches && item.productAttaches.length"
                    >
                    <el-carousel-item v-for="(i, idx) in item.productAttaches" :key="idx+'p'+index+'c'" >
                      <div class="carousel-content" >
                        <!--中间放一个播放按钮 如果视频正在播放 则隐藏播放按钮  -->
                        <div class="flex justify-center items-center w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 "
                        style="top: 30%;"
                          v-if="i.type === 'video' && !isVideoPlaying(index, idx)"
                          @click.stop="jumpTo(item)"
                        >
                          <img src="@/assets/images/playIcon.png" alt="播放"  />
                        </div>
                        <img v-if="i.type === 'video'" class="w-full h-full" :src="i.thumbFilePathTemp || videoImage" alt="图片" />
                      <!-- <video v-if="i.type === 'video' && carouselReady"  @ended="handleVideoEnded"
                                      :ref="(el) => { if(el) setVideoRef(el, index, idx) }"
                         width="100%" :autoplay="false"
                        @play="handleVideoPlay(index, idx, $event)"
                        :poster="i.thumbFilePathTemp || videoImage"
                        playBtnPosition="center"
                         muted controls>
                        <source :src="i.filePathTemp" type="video/mp4" />
                      </video> -->
                      <img v-else class="w-full h-full" :src="i.filePathTemp" alt="图片" />
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                  <el-empty v-else description="暂无数据" />
                </div>
                <div class="list-des" >
                  <div class="flex  items-center justify-between" :style="{
                    width: item.coverTemp ? 'calc(100% - 230px)' : '100%',
                  }">
                  <p class="list_title truncate text-[17px] font-bold mr-2 " style="margin-bottom: 4px;">
                    {{ item.title }}
                  </p>
                  <div class="consultNum flex items-center text-[13px] text-[#999]" v-if="item.consultNum" style="font-size: 12px;">
                    <svg-icon icon-class="kefu" class="w-3 h-3 mr-1" ></svg-icon>
                    <span class="text-[#999] truncate"> {{ item.consultNum }} 用户咨询</span>
                </div>
              </div>
                <div class="information_des">
                  {{ item.subtitle }}
                  </div>
                </div>
              </div>

            </div>
          </el-scrollbar>
        </div>
      </div>
      <el-empty description="暂无数据" v-if="!list.length" />
    </div>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script setup>
import { defineProps, nextTick, onMounted, ref, onBeforeUnmount, watch } from "vue";
import {
  getApi,
} from "@/api";
import { useRoute, useRouter } from "vue-router";
import { ElScrollbar, ElMessage } from "element-plus";
import videoImage from '@/assets/img/video-post.jpg'
const list = ref([]);

const router = useRouter();
const route = useRoute()
const playingStates = ref({}); // 存储视频播放状态
const videos = ref([]); 
const carouselReady = ref(false); // 跟踪每个轮播项是否准备好
const interval = 5000; // 设置走马灯间隔时间
// 修改 ref 的定义
const carousels = ref([]);
//跟踪当前正在播放的视频
const currentPlaying = ref({
  itemIndex: null,  // 列表项索引
  videoIndex: null, // 视频索引
  video: null       // 视频元素
});
const setCarouselsRef = (el, index) => {
  carousels.value[index] = el;

};
// 视频暂停事件处理
const handleVideoPause = (index, videoIndex, event) => {
  const video = event.target;
  
  // 如果是当前播放的视频被暂停，清除当前播放状态
  if (currentPlaying.value.video === video) {
    currentPlaying.value = {
      itemIndex: null,
      videoIndex: null,
      video: null
    };
  }
  
  // 更新播放状态
  playingStates.value[`${index}-${videoIndex}`] = false;
  console.log('Video paused:', index, videoIndex);
};

// 检查视频是否正在播放
const isVideoPlaying = (index, videoIndex) => {
  return playingStates.value[`${index}-${videoIndex}`] || false;
};
const handleVideoEnded = () => {
  // 重置播放状态
  currentPlaying.value = {
    itemIndex: null,
    videoIndex: null,
    video: null
  };
  
 // 如果需要，可以自动切换到下一个
 setTimeout(() => {
    const { itemIndex } = currentPlaying.value;
    if (carousels.value[itemIndex]) {
      carousels.value[itemIndex].next();
    }
  }, 500);

};
// 设置视频ref的辅助函数
const setVideoRef = (el, itemIndex, videoIndex) => {
  if (!videos.value[itemIndex]) {
    videos.value[itemIndex] = [];
  }
  videos.value[itemIndex][videoIndex] = el;
};
const handleChange = (newIndex, oldIndex, itemIndex) => {
  carouselReady.value=false

  // 如果当前轮播图中有正在播放的视频，暂停它
  if (currentPlaying.value.itemIndex === itemIndex) {
    pauseAllVideos();
  }
  setTimeout(()=>{
    carouselReady.value = true;
  },1)
};

// 视频播放事件处理
const handleVideoPlay = (itemIndex, videoIndex, event) => {
  emit("noPlay",true)
  const video = event.target;
  // 如果不是当前正在播放的视频，暂停其他视频
  if (currentPlaying.value.video !== video) {
    // 暂停之前的视频
    if (currentPlaying.value.video && !currentPlaying.value.video.paused) {
      currentPlaying.value.video.pause();
    }
    
    // 更新当前播放状态
    currentPlaying.value = {
      itemIndex,
      videoIndex,
      video
    };
  }

  playingStates.value[`${itemIndex}-${videoIndex}`] = true;
};
// 点击自定义播放按钮
const openPlay = (itemIndex, videoIndex) => {
  console.log("!23131")
  event.stopPropagation(); // 阻止事件冒泡
  const video = videos.value[itemIndex]?.[videoIndex];
  if (!video) return;

  // 暂停其他视频
  if (currentPlaying.value.video && currentPlaying.value.video !== video) {
    currentPlaying.value.video.pause();
  }

  // 播放当前视频
  video.play();
  // 更新播放状态
  playingStates.value[`${itemIndex}-${videoIndex}`] = true;
  // 更新当前播放视频信息
  currentPlaying.value = {
    itemIndex,
    videoIndex,
    video
  };
}


// 播放/暂停处理函数
const togglePlayPause = (itemIndex, videoIndex) => {
  const video = videos.value[itemIndex]?.[videoIndex];
  if (!video) return;

  // 如果点击的是当前正在播放的视频
  if (currentPlaying.value.video === video) {
    video.pause();
    currentPlaying.value = {
      itemIndex: null,
      videoIndex: null,
      video: null
    };
    return;
  }

  // 暂停所有视频
  pauseAllVideos();
  // 播放新的视频
  video.play();
  setTimeout(() => {
    currentPlaying.value = {
      itemIndex,
      videoIndex,
      video
    };
  // 更新播放状态
  playingStates.value[`${itemIndex}-${videoIndex}`] = true;
  }, 1000);
};
// 暂停所有视频的方法
const pauseAllVideos = () => {
  // 遍历所有轮播图中的所有视频
  videos.value.forEach((videoGroup, groupIndex) => {
    videoGroup.forEach((video, videoIndex) => {
      if (video && !video.paused) {
        video.pause();
      }
    });
  });
  
  // 重置当前播放状态
  currentPlaying.value = {
    itemIndex: null,
    videoIndex: null,
    video: null
  };
};
const emit = defineEmits(['changeId','noPlay'])
// 详情跳转
const jumpTo = (row) => {
  console.log(row)
  // 跳转详情页 如果当前页面就是在 详情页，那么给父传递id 变更
  if (route.name === 'homePageDetails') {
    emit('changeId', row.id)
  } else {
    router.push({ name: 'homePageDetails', query: { id: row.id } })
  }
};

const props = defineProps({
  // 子组件接收父组件传递过来的值
  isAll: Boolean,
  // 是否显示分类
  showTabs: {
    type: Boolean,
    default: true,
  },
  productId:{
      type:String,
      default:'',
  },
  title: String,
});
const scrollbarRef = ref();
const getList = () => {
  getApi("/homepage/web/productList", {}).then((res) => {
    if (res.code === 200) {
      let data = res.data;
      // 循环info.productAttaches 里面的fileName  给内容添加type 优先播放视频 currentIdx
      // 常见的视频格式
      const videoExtensions = [
        "mp4",
        "avi",
        "mov",
        "mkv",
        "flv",
        "wmv",
        "webm",
        "mpeg",
      ];
      // 常见的图片格式
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "webp",
        "tiff",
        "svg",
      ];
      data.forEach((itm, idx) => {
        itm.productAttaches&&itm.productAttaches.forEach((item, index) => {
          if (videoExtensions.includes(item.fileName.split(".").pop().toLowerCase())) {
            item.type = "video";
          } else if (imageExtensions.includes(item.fileName.split(".").pop().toLowerCase())) {
            item.type = "image";
          } else {
            item.type = "other";
          }
        });
        // 将 itm.productAttaches 排序 优先播放视频
        itm.productAttaches&&itm.productAttaches.sort((a, b) => {
          return a.type === 'video' ? -1 : 1;
        });
      });
      setTimeout(()=>{
            carouselReady.value = true;
          },1)
      nextTick(() => {
        list.value = data;
      })
    }
  });
};
// 组件卸载时清理
onBeforeUnmount(() => {
  pauseAllVideos();
});

// 监听路由变化，在页面切换时暂停所有视频
watch(() => route.path, () => {
  pauseAllVideos();
});
defineExpose({ getList });
</script>
<style scoped lang="less">
::v-deep(.el-carousel__indicators) {
  display: none;
}
.carousel-content {
      width: 100%;
      height: 100%;
      position: relative;

      video,
      img {
        width: 100%;
        height: 100%;
      }
    }
.informationList {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;

  .scrol {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .no_detials_title_box {
    padding: 0 20px;
    border-bottom: 1px solid #e6e6e6;

    .p_h_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #020c33;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
    }

    .p_h_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8c8c8c;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8c8c8c;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }
  }

  ::v-deep(.el-input__wrapper) {
    border-radius: 30px;
  }

  ::v-deep(.el-switch__label) {
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 400;
    width: 56px;
  }

  ::v-deep(.el-tabs__item) {
    padding: 0 15px;
  }

  .tabs_item {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    padding: 2px 14px;
    cursor: pointer;
  }

  .tabs_item.active {
    color: var(--el-color-primary);
    border-bottom: 2px solid var(--el-color-primary);
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    //text-stroke: 1px rgba(0, 0, 0, 0);
    //text-align: left;
    //font-style: normal;
    //text-transform: none;
    //-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right1 {
    position: absolute;
    left: 50%;
    width: 62%;
    margin-left: -32%;
    border: 1px solid #e6ebf1;
  }

  .right {
    height: 100%;
    border-radius: 8px;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: #3f9dfd;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .list_bottom {
      height: calc(100% - 140px);
    }

    .h-list_bottom {
      height: calc(100%);
    }

    .list {
      /* overflow: hidden; */
      height: 100%;
      .list_img{
        height: 275px;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
        video{
          height: 275px;
          width:auto;
        }
      }
      .list-des{
        padding:10px 20px;
      }
      .consultNum{
        color: #999;
        font-size: 13px;
      }
      .information_des{
        color: #666;
        font-size: 14px;
      }
      .star_icon {
        position: absolute;
        right: 20px;
        top: 3px;
      }

      .list_item {
        // padding: 0 20px;
        margin-bottom: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020c33;
        line-height: 30px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

        .information_img {
          display: inline-block;
          width: 184px;
          height: 103px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #e6e6e6;
        }

        .list_title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 16px;
          color:#333;
          text-stroke: 1px rgba(0, 0, 0, 0);
          text-align: left;
          font-style: normal;
          text-transform: none;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
        }

        .list_ite_left {
          flex: 0 0 auto;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8c8c8c;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 184px;
          //margin-left: 20px;
        }

        .list_ite_left_text {
          flex: 0 0 auto;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8c8c8c;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
        }

        .no_p_1 {
          display: inline-block;
          background: #edeff7;
          border-radius: 4px 4px 4px 4px;
          padding: 2px 10px;
          margin-right: 6px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #666666;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .information_des {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
      .list-item-box-active{
        background: var(--el-color-primary-light-9);
        .list_title {
          color: var(--el-color-primary);
        }
      }
      .list_item-box:hover {
        background: var(--el-color-primary-light-9);
        .list_title {
          color: var(--el-color-primary);
        }
      }
    }

    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .icon_btn_kjj {
      border-color: var(--el-button-border-color);
      font-size: var(--el-font-size-base);

      .svg-icon {
        height: 20px;
        width: 20px;
      }
    }

    .icon_btn_more {
      .svg-icon {
        height: 20px;
        width: 20px;
        color: var(--el-color-primary);
      }
    }
  }
}
::v-deep(.el-empty__description p){
  color: #999 !important;
  font-weight: 300 !important;
  margin-top: 13px!important;
}
::v-deep(.el-empty__image){
  width: 100px !important;

}
</style>
<style lang="postcss">
.icon-tabs {
  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__header {
    margin: 0 0 0px;
  }
}
</style>
