import request from '@/utils/request'
const api = {
    noticeList: '/api/guestManage/notice/market/on/display/notice',
    saveApply: '/api/guestManage/notice/market/declare/sth',
    getCode: '/api/guestManage/notice/market/send/msg',
}


// 获取展示中的公告
export function getNoticeListApi() {
    return request({
        url: api.noticeList,
        method: 'POST',
    })
}
// 提交申报
export function saveApplyApi(data) {
    return request({
        url: api.saveApply,
        method: 'POST',
        data: data
    })
}
// 获取验证码
export function getCodeApi() {
    return request({
        url: api.getCode,
        method: 'get',
        responseType: 'arraybuffer', // arraybuffer blob
    })
}
