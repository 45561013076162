<template>
  <el-menu :mode='mode' :collapse-transition='false' id="menuIds"
           :default-active="defaultActive"
           :default-openeds="defaultMenuGroup"
           class="overflow-hidden menuListClass"
           popper-class="user-poper"
           :class="mode==='vertical'&&collapse?'is-collapse':''"
           :collapse="collapse"
           :key="menuIndex"
           :style="{width: mode==='vertical'?collapse?'78px':'216px':'100%'}"
  >
    <SidebarItem
        v-for="(route, index) in mode==='vertical'?menuList:visibleMenuItems"
        :key="index"
        :mode='mode'
        :indexKey="index.toString()"
        :item="route"
    >
    </SidebarItem>
    <!-- “更多”菜单项12312 -->
    <el-sub-menu index="more" v-if="hasMoreItems && mode!=='vertical'" class="more_menu_border">
      <template #title>
        <svg-icon icon-class="more-menu" class-name="more-icon"
                  style="margin-left: 0px;margin-right: 0px; width:16px"></svg-icon>
      </template>
      <!-- 超出的菜单项 -->
      <SidebarItem
          v-for="(route, index) in moreMenuItems"
          :key="index"
          :mode='mode'
          :indexKey="(index+menuNum).toString()"
          :item="route"
          :isIcon="true"
      >
      </SidebarItem>
    </el-sub-menu>
  </el-menu>

</template>
<script>
import actions from "@/action";
import store from "@/store";
import "@/utils/registerMicroAppsConfig"
import {mapState} from "vuex";
import {getAppInfo, getMenuPrv, getOrgInfoByUser, logout} from "@/api";
import comAbout from '@/views/About.vue'
import comLogin from "@/views/login.vue";
import {appsData, dynamicRegisterApps} from "@/utils/registerMicroAppsConfig";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import SidebarItem from './menu/silderItem.vue'

export default {
  name: "sliderMenu",
  components: {
    SvgIcon,
    comAbout,
    SidebarItem,
  },
  computed: {
    ...mapState([
      "headerImg",
    ]),
    // 计算属性，用于确定前 8 个菜单项
    visibleMenuItems() {
      return this.menuList.slice(0, this.menuNum);
    },
    // 计算属性，用于确定是否有多余的菜单项
    hasMoreItems() {
      return this.menuList.length > this.menuNum;
    },
    // 计算属性，用于确定超出部分的菜单项
    moreMenuItems() {
      return this.menuList.slice(this.menuNum);
    }
  },
  watch: {
    // 监听menuNum的变化
    menuNum(newVal, oldVal) {
      // 当menuNum变化时，执行以下代码
      this.menuIndex++; // 增加menuIndex的值
      // 这里可以添加其他当menuNum变化时需要执行的逻辑
    }
  },
  props: {
    mode: {
      type: String,
      default: 'horizontal'
    },
    collapse: {
      type: Boolean,
      default: false
    },
    defaultActive: {
      type: String || Number,
      default: ''
    },
    menuNum: {
      type: Number,
      default: 5
    },
    defaultMenuGroup: {
      default: []
    },
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      menuIndex: 0,
      n: '<svg t="1719814952515" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="899" xmlns:xlink="http://www.w3.org/1999/xlink"  ><path d="M258.9 71.6c-104.5 0-189.1 84.7-189.1 189.1s84.7 189.1 189.1 189.1H448V260.7c0-104.4-84.6-189.1-189.1-189.1zM385 386.8H258.9c-69.5 0-126.1-56.6-126.1-126.1s56.6-126.1 126.1-126.1S385 191.2 385 260.7v126.1zM952.4 260.7c0-104.5-84.7-189.1-189.1-189.1s-189.1 84.7-189.1 189.1v189.1h189.1c104.4 0.1 189.1-84.6 189.1-189.1z m-315.2 0c0-69.5 56.6-126.1 126.1-126.1s126.1 56.6 126.1 126.1-56.6 126.1-126.1 126.1H637.2V260.7zM69.8 765.1c0 104.5 84.7 189.1 189.1 189.1S448 869.6 448 765.1V576H258.9c-104.5 0-189.1 84.6-189.1 189.1z m315.2 0c0 69.5-56.6 126.1-126.1 126.1s-126.1-56.6-126.1-126.1S189.4 639 258.9 639H385v126.1zM763.3 576H574.1v189.1c0 104.5 84.7 189.1 189.1 189.1s189.1-84.7 189.1-189.1S867.7 576 763.3 576z m0 315.2c-69.5 0-126.1-56.6-126.1-126.1V639h126.1c69.5 0 126.1 56.6 126.1 126.1s-56.6 126.1-126.1 126.1z" p-id="900"></path></svg>',
      defaultMenuId: "",
      userAvatar: '',
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      appList: [],
      isHomeState: true
    }
  },
  mounted() {

  },
}
</script>
<style lang="postcss">

.menuListClass.el-menu--vertical {
  /* 菜单移入效果 */

  .el-menu-item:hover {
    /* background-color: var(--el-menu-hover-bg-color-z);*/
     background-color: transparent; 

    .menu_title_h1 {
      color: var(--el-menu-hover-h1-color) !important;
      position: relative;
      font-size: 16px;
    }
  }

  .el-sub-menu .el-menu-item:hover {
      background-color: transparent; 

    .menu_title_er_h1 {
      color: var(--el-menu-hover-h2-color) !important;
      position: relative;
    }
  }


  .el-sub-menu__title .el-icon {
    color: var(--el-menu-icon-arrow-color) !important;
  }

  .el-sub-menu.is-active {
    .el-sub-menu__title {
      /*.menu_title_h1{
        color: var(--el-menu-active-h1-color) !important;
      }*/
    }
    .el-sub-menu__title:hover{
      .el-icon {
        color: var(--el-menu-icon-arrow-active-color) !important;
      }
    }
  }

  /*  .el-sub-menu:hover {
      background-color: var(--el-menu-hover-bg1-color) !important;
      color: var(--el-menu-hover-h1-color)
    }*/

  .more-icon {
    color: var(--el-menu-more-color) !important;
  }

  .el-menu-item {
    //height: 40px;
    //margin: 10px 0;

    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 30px;
      margin-right: 8px;
    }

  }

  .el-sub-menu {
    .el-menu {
      background: var(--el-menu-bg2-color);
    }
  }


  .el-sub-menu__title {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 30px;
      margin-right: 8px;
    }
  }

  .el-menu-tooltip__trigger {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 10px !important;
      margin-right: 0px !important;
    }
  }


}

.menuListClass.el-menu--collapse {
  .svg-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: 10px !important;
    margin-right: 0px !important;
    flex-shrink: 0 !important;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.el-popper {
  .el-menu {
    .svg-icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: 10px;
      margin-right: 8px;
    }
  }


}

.menuListClass.el-menu--horizontal .more_menu_border.is-active .el-sub-menu__title::after {
  display: block;
}
</style>

<style>
.more_menu_border {
  /* 确保“更多”菜单项的样式不会导致它被隐藏 */
  display: block;
  position: relative;
}

/* 可以添加一些额外的样式来确保“更多”菜单项的可见性 */
.more_menu_border .el-sub-menu__title {
  /* 样式示例，根据需要调整 */
  visibility: visible;
}
</style>
