<template>
  <div class="informationList">
    <!-- <el-scrollbar height='100%'> -->
    <div class="right bg-white" :class="props.isAll ? 'right1' : 'w-full'">
      <div class="p20 flex  items-center justify-between">
        <div class="flex items-center justify-between mr-2"
          :style="{ width: props.isAll ? 'calc(100% - 440px)' : 'calc(100% - 60px)' }" style="justify-content: normal;">
          <h5 class="inline-block flex-shrink-0" style="margin-right: 61px;">{{
      props.title ? props.title : '资讯列表'
    }} </h5>
          <div class="inline-block scrol" :style="{ width: props.isAll ? '60%' : '70%' }" v-if="showTabs && !noToken">
            <el-tabs v-model="activeName" class="flex-1 icon-tabs" @tab-change="handleClick">
              <el-tab-pane label="全部" name=""></el-tab-pane>
              <el-tab-pane v-for="i in tabsList" :label="i.infoCategoryName" :name="i.infoCategoryId"></el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="flex items-center flex-shrink-0" :style="{ width: props.isAll ? 'auto' : '60px' }">
          <div v-if="props.isAll && !noToken" class="flex items-center">
            <el-input v-model="keyWordSearch" style="width: 250px;" placeholder="搜索资讯" :suffix-icon="Search"
              @input="getList" />
            <el-switch v-model="value2" :active-value="1" :inactive-value="0" style="margin: 0 20px;"
              :active-text="value2 ? '仅我收藏' : '全部'" inactive-text="" @change="getList" class=" flex-shrink-0" />
            <!--          <span >全部</span>-->

            <div class="inline-block flex-shrink-0">
              <el-dropdown trigger="click" max-height="500">
                <div class="icon_btn_kjj icon_btn_more" v-ripple:color="'rgba(169,169,169,0.3)'">
                  <svg-icon icon-class="more-all"></svg-icon>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, index) in tabsAllList" :divided="index > 0">
                      <div class="flex items-center justify-between" style="width: 200px;">
                        <span class="truncate"> <el-tooltip class="box-item" effect="dark"
                            :content="item.infoCategoryName" placement="top">
                            {{ item.infoCategoryName }}
                          </el-tooltip> </span>
                        <el-switch v-model="item.subscribeFlag" style="margin: 0 0 0 20px;" active-value="1"
                          inactive-value="0" :active-text="item.subscribeFlag == 1 ? '已订阅' : '未订阅'"
                          @change="infoCategoryChange($event, item.infoCategoryId)" />
                      </div>

                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </div>
          </div>
          <el-link type="primary" v-if="!props.isAll" @click="seeMore">查看更多</el-link>
        </div>
      </div>
      <div v-if="list.length" :class="props.isAll ? 'list_bottom' : 'h-list_bottom'">
        <div class="list">
          <el-scrollbar ref="scrollbarRef" height="100%">
            <div :class="props.isHome ? 'list-grid' : ''">
            <div class="list_item cursor-pointer" :class="!props.isHome ? 'px20' : ''" v-for="item in list">
              <div class="flex  relative" >
                <div class="star_icon" v-if="!props.isHome">
                  <el-icon size="16" color="#B3B3B3" @click.stop="collectInfo(item.id)" v-if="item.collectFlag == '0' || props.isCollect ">
                    <svg-icon icon-class="star"></svg-icon>
                  </el-icon>
                  <el-icon size="16" v-else>
                    <svg-icon icon-class="star-active" @click.stop="collectInfo(item.id)" v-if="item.collectFlag == 1" ></svg-icon>
                  </el-icon>
                </div>

                <div class="list_ite_left m m_r_20" v-if="item.coverTemp">
                  <img :src="item.coverTemp" alt="" class="information_img">
                </div>
                <div :style="{width: item.coverTemp ? 'calc(100% - 240px)' : '100%'}">
                  <p class="list_title truncate" @click="jumpTo(item)">{{ item.title }}</p>
                  <div class="mt-2">
                    <p class="no_p_1" v-for="i in item.infoCategories">
                      {{ i }}
                    </p>

                  </div>
                  <div class="mt-2 information_des">
                    {{ item.subtitle }}
                  </div>

                </div>
              </div>

              <div class="flex justify-between list_ite_left_text" >
                  <span class="flex items-center">
                    <el-icon style="margin-right: 5px;">
                      <View />
                    </el-icon>
                    {{ item.viewTimes || 0 }}</span>
                <span> 发布于：{{ getTimeAgoText(new Date(item.publishDate), 'YYYY-MM-DD') }}</span>
              </div>
            </div>
            </div>
          </el-scrollbar>

        </div>

      </div>
      <el-empty description="暂无数据" v-if="!list.length" />
      <div class="flex justify-end pagination" v-if="props.isAll">
        <el-pagination small background layout=' prev, pager, next,sizes, jumper' :total='paginations.total'
          :page-size="paginations.pageSize" :current-page='paginations.pageNo'
          :default-current-page='paginations.pageSize' :popper-append-to-body='false' @size-change='sizeChange'
          @current-change='currentChange' />
      </div>
    </div>
    <!-- </el-scrollbar> -->
  </div>
</template>


<script setup>

import dicTag from "@/components/DicTag/index.vue";
import { defineProps, onMounted, ref } from "vue";
import { Search, View, Star, StarFilled, HomeFilled } from "@element-plus/icons-vue";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import { getTimeAgoText } from "@/utils/tool";
import { collectInfoApi, postApi,getApi, getInfoListmessage, getOrgInfoCategoryListApi, subscribeInfoCategoryApi } from "@/api";
import { useRouter } from "vue-router";
import { ElScrollbar , ElMessage } from "element-plus";

const paginations = ref({
  pageNo: 1,
  pageSize: 20,
  total: null,
  sortList: []
});
const keyWordSearch = ref('')
const value2 = ref(0)
const list = ref([])
const noToken = ref(true)

const sizeChange = (size) => {
  paginations.value.pageNo = 1;
  paginations.value.pageSize = size;
  getList();
};
// 详情跳转
const jumpTo = (row) => {
  router.push({
    path: "/informationDetails",
    query: {
      id: row.id,
      home: props.isHome?1:0
    }
  })
}
const currentChange = (num) => {
  paginations.value.pageNo = num;
  getList();
};
const props = defineProps({
  // 子组件接收父组件传递过来的值
  isAll: Boolean,
  isHome: {
    type: Boolean,
    default: false
  },
  // 是否显示分类
  showTabs: {
    type: Boolean,
    default: true
  },
  title: String,
  isCollect: {
    type: Boolean,
    default: false
  }

})

// 收藏和取消收藏
const collectInfo = (id) => {
  console.log(1111,"click")
  let params = {
    infoId: id
  }
  collectInfoApi(params).then(res => {
    if (res.code === 200) {
      console.log(res)
      getList()
    }
  })
}
const tabsList = ref([])
const tabsAllList = ref([])
const infoCategoryChange = (e, id) => {
  subscribeInfoCategoryApi({ infoCategoryId: id }).then(res => {
    if (res.code === 200) {
      ElMessage.success(res.msg || "操作成功")
      getOrgInfoCategoryList()
    }
  })
}
//组织已订阅资讯分类列表
const getOrgInfoCategoryList = () => {
  getOrgInfoCategoryListApi().then(res => {
    if (res.code == 200) {
      let data = JSON.parse(JSON.stringify(res.data))
      tabsList.value = data.filter(item => item.subscribeFlag == 1)
      tabsAllList.value = data
      activeName.value = ''
      getList()
    }
  })
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
const router = useRouter()
// 查看更多
const seeMore = () => {
  router.push({ path: "/informationList" })
}
const activeName = ref("")
const handleClick = () => {
  getList()
}
const typeList = ref([
  {
    label: "审批通知",
    value: 1
  },
  {
    label: "任务通知",
    value: 2
  }
])
const scrollbarRef = ref()
const getList = () => {
  noToken.value = false
  // 判断如果没有token 就调用另一个接口 
  if(sessionStorage.getItem("token")){
  let params = {
    "infoCategoryId": activeName.value,
    "collectFlag": value2.value,
    "keyword": keyWordSearch.value,
    "pageNo": paginations.value.pageNo,
    "pageSize": paginations.value.pageSize
  }
  scrollbarRef.value?.setScrollTop(0)
  getInfoListmessage(params).then(res => {
    if (res.code === 200) {
      list.value = res.data.records
      paginations.value.total = res.data.total
    }
  })
}else{
  noToken.value = true
  postApi( '/homepage/web/infoPageList',{
    "pageNo": paginations.value.pageNo,
    "pageSize": paginations.value.pageSize
  }).then(res => {  
    if (res.code === 200) {
      list.value = res.data.records
      paginations.value.total = res.data.total
    }
  })
}
}
const getHomePage = () => {
  getApi('/homepage/web/infoList').then(res => {
    list.value = res.data
  })
}
defineExpose({ getList,getHomePage, getOrgInfoCategoryList })
</script>
<style scoped lang="postcss">
.informationList {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;
  .list {
    height: 100%;
    .px20{
      padding: 0 20px;
    }
    .list-grid {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 0px;
      
      @media only screen and (min-width: 1550px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      
        &::after {
          content: '';
          position: absolute;
          top: 20px; 
          bottom: 20px;
          left: 50%;
          width: 1px;
          background-color: #E6EBF1; 
          transform: translateX(-50%);
        }
        .list_item:nth-child(2n){
          margin-left: 20px;
        }
      }
    }
    
  }
  
  
  .list_bottom, .h-list_bottom {
    height: calc(100% - 60px);
  }
  
 

  .scrol{
    overflow-x: auto;
    overflow-y: hidden;
  }
  .no_detials_title_box {
    padding: 0 20px;
    border-bottom: 1px solid #E6E6E6;

    .p_h_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #020C33;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
    }

    .p_h_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }
  }

  ::v-deep(.el-input__wrapper) {
    border-radius: 30px;
  }

  ::v-deep(.el-switch__label) {
    font-size: 14px;
    color: #8C8C8C;
    font-weight: 400;
    width: 56px;

  }
  ::v-deep(.el-tabs__item){
    padding: 0 15px;
  }

  .tabs_item {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    padding: 2px 14px;
    cursor: pointer;
  }

  .tabs_item.active {
    color: var(--el-color-primary);
    border-bottom: 2px solid var(--el-color-primary);
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    //text-stroke: 1px rgba(0, 0, 0, 0);
    //text-align: left;
    //font-style: normal;
    //text-transform: none;
    //-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right1 {
    position: absolute;
    left: 50%;
    width: 62%;
    margin-left: -32%;
    border: 1px solid #E6EBF1;
  }

  .right {
    height: 100%;
    border-radius: 8px;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: #3F9DFD;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .list_bottom {
      height: calc(100% - 140px);
    }

    .h-list_bottom {
      height: calc(100% - 80px);
    }

    .list {
      /* overflow: hidden; */
      height: 100%;

      .star_icon {
        position: absolute;
        right: 20px;
        top: 3px;
      }

      .list_item {
        width: calc(100% - 20px);
        margin-bottom: 30px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020C33;
        line-height: 30px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

        .information_img {
          display: inline-block;
          width: 184px;
          height: 103px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #E6E6E6;
        }

        .list_title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 14px;
          color: #020C33;
          text-stroke: 1px rgba(0, 0, 0, 0);
          text-align: left;
          font-style: normal;
          text-transform: none;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
        }

        .list_ite_left {
          flex: 0 0 auto;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8C8C8C;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 184px;
          //margin-left: 20px;
        }
        .list_ite_left_text{
          flex: 0 0 auto;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8C8C8C;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
        }

        .no_p_1 {
          display: inline-block;
          background: #EDEFF7;
          border-radius: 4px 4px 4px 4px;
          padding: 2px 10px;
          margin-right: 6px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #666666;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .information_des {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .list_item:hover {
        .list_title {
          color: var(--el-color-primary);
        }
      }
    }

    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .icon_btn_kjj {
      border-color: var(--el-button-border-color);
      font-size: var(--el-font-size-base);

      .svg-icon {
        height: 20px;
        width: 20px;
      }
    }

    .icon_btn_more {
      .svg-icon {
        height: 20px;
        width: 20px;
        color: var(--el-color-primary);
      }
    }
  }
}

</style>
<style lang='postcss'>
.icon-tabs {
  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__header {
    margin: 0 0 0px;
  }
}
</style>
