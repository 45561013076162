<template>
  <div class="home-main">
   
      <Nav
      :is-menu="false"
      :userInfo="{}"
      :shortcutKeyList="{}"
      menuList="[]"
      :isLogin="isLoginState"
      :isHomeShow="true"
      defaultMenuGroup=""
      defaultMenuId=""
      ref="navRef"
    ></Nav>

    <div class="layout flex" :style="{ height: 'calc(100% - 96px)' }">
      <div class="layout-main" :class="'pt-20'" :style="{ width: ' 100%' }">
        <div class="home_right w-full h-full bg-white relative flex justify-between" style="height: calc(100%)">
          <div class="home-left relative  h-full   border-card">
            <el-scrollbar height="100%">
              <div class="flex  justify-between items-center locateTop">
                <h5 class="text-[#fff]" style="text-shadow: 0px 1px 2px rgba(0,0,0,0.3);"> {{ info.title }}</h5>
                <div class="star_icon flex ">
                  <div class="mt-2 user-num flex items-center ">
                    <span class="kefu-icon mr-1"></span>
                    {{ info.consultNum || '--' }} 用户咨询
                  </div>
                  <el-button class="ml-2" type="primary" @click="goLogin">试用
                  </el-button>
                </div>
              </div>

              <div class="no_detials_title_box">
                <div class="video-wrapper relative"   v-if="info.productAttaches && info.productAttaches.length">
                  <el-carousel ref="carousel" :interval="interval" @change="carouselChange" :height="'auto'" :autoplay="false"
                  :loop="false"
                  :initial-index="0"
                
                  >
                    <el-carousel-item v-for="(item, index) in info.productAttaches" :key="index" style="height: 100%;">
                      <div class="carousel-content">
                        <!--中间放一个播放按钮 如果视频正在播放 则隐藏播放按钮 -->
                        <div class="flex justify-center items-center w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          v-if="item.type === 'video' && !playingStates[index]"
                          @click="togglePlayPause(index)"
                        >
                          <img src="@/assets/images/playIcon.png" alt="播放"  />
                        </div> 
                        <!-- 视频容器 -->
          <video 
          class="border-radius-8"
            v-if="item.type === 'video'&&carouselReady"
            :ref="(el) => (videos[index] = el)"
            @ended="handleVideoEnded" 
            @play="() => handleVideoPlay(index)"
            @pause="() => handleVideoPause(index)"
            :autoplay="false" 
            controls
            :poster="item.thumbFilePathTemp || videoImage"
            muted
            preload="metadata"
          >
            <source :src="item.filePathTemp" type="video/mp4">
          </video>
                       
                        <img class="w-full h-full" v-else :src="item.filePathTemp" alt="图片" />
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                
                </div>
                <el-empty  v-if="!info.productAttaches || !info.productAttaches.length" style="margin-top: 200px;" description="暂无数据" />
                <div class="content-section">
                  <div class="mt-2 m_b_20 p20">
                    <span class=" mr-3  text-[18px] font-bold text666 ">{{ info.subtitle }}</span>
                    <p class="m_t_20  font-bold text333 introduction" v-if="info.introduction">产品介绍：</p>
                    <p class="p_h_2 text333 text" v-if="info.introduction">{{ info.introduction }}</p>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="home-right  m_l_20  h-full  ">
            <productList title="产品一览" ref="productListRef" :isAll="false" :showTabs="false" :productId=productId
            @noPlay="noPlay"
              @changeId="changeId"></productList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineProps, nextTick, onMounted, ref } from "vue";
import { collectInfoApi, getApi, getInfoListmessage, getOrgInfoCategoryListApi, subscribeInfoCategoryApi } from "@/api";
import { useRouter, useRoute } from "vue-router";
import { ElScrollbar, ElMessage } from "element-plus";
import Nav from "@/components/Nav.vue";
import productList from "@/views/User/productList.vue";
import videoImage from "@/assets/img/video-post.jpg"
import {
  checkToken,
} from "@/api";
const router = useRouter()
const route = useRoute()
const carousel = ref();
const videos = ref([]);
const isLoginState = ref(false);
const interval = 5000; // 设置走马灯间隔时间

const currentIdx = ref(0);
const productId = ref('');
const noPlay =()=>{

}
const carouselReady = ref(false); // 跟踪每个轮播项是否准备好
// 校验token是否有效
const checkTokenApi = () => {
  let token = sessionStorage.getItem("token");
  if (!token) {
    isLoginState.value = false;
  } else {
    checkToken({ token: JSON.parse(token) }).then(res => {
      if (res.code == 200) {
        isLoginState.value = true;
      }
    })
  }
}
// 去登录页面
const goLogin = () => {
  // 判断是否登录 登录去工作台页面
  if (isLoginState.value) {
    router.replace({
      path: '/app',
    })
  } else {
    router.replace({
      path: '/login',
    })
  }
}
const changeId = (id) => {
  productId.value = id;
  info.value = {}
  getInfoListmessageApi()
}
// 轮播图切换 关闭全部视频
const carouselChange = (index) => {
  carouselReady.value = false

  // 暂停所有视频
  videos.value.forEach((video, index) => {
    if (video && !video.paused) {
      video.pause();
      video.currentTime = 0;
      playingStates.value[index] = false;
    }
  });
  pauseAllVideos()
  // 确保新的视频正确显示
  nextTick(() => {
  setTimeout(() => {
    carouselReady.value = true;
  }, 1);
  });
}
const playingStates = ref({}); // 使用对象来存储每个视频的播放状态
const togglePlayPause = (index) => {
  const video = videos.value[index];
  if (video.paused) {
    video.play(); 
  } else {
    video.pause();
  }
};
// 添加视频事件监听
const handleVideoPlay = (index) => {
  playingStates.value[index] = true;
};

const handleVideoPause = (index) => {
  playingStates.value[index] = false;
};
const handleVideoEnded = (index) => {
  playingStates.value[index] = false;
  setTimeout(() => {
    if (carousel.value) {
      carousel.value.next();
    }
  }, 500);
};
const pauseAllVideos = () => {
  videos.value.forEach((video) => {
    if (!video.paused) {
      video.pause();
    }
  });
};

const productListRef = ref()
const paginations = ref({
  pageNo: 1,
  pageSize: 20,
  total: null,
  sortList: []
});
/* 去详情页 */
const info = ref({
  title: ''
})
// 获取详情页数据
const getInfoListmessageApi = () => {
  getApi('/homepage/web/productDetail', { id: productId.value }).then(res => {
    if (res.code === 200) {
      let data = res.data;
      // 循环info.productAttaches 里面的fileName  给内容添加type 优先播放视频 currentIdx
      // 常见的视频格式
      const videoExtensions = [
        "mp4",
        "avi",
        "mov",
        "mkv",
        "flv",
        "wmv",
        "webm",
        "mpeg",
      ];
      // 常见的图片格式
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "webp",
        "tiff",
        "svg",
      ];
      data.productAttaches.forEach((item, index) => {
        if (videoExtensions.includes(item.fileName.split(".").pop().toLowerCase())) {
          item.type = "video";
          currentIdx.value = index;
        } else if (imageExtensions.includes(item.fileName.split(".").pop().toLowerCase())) {
          item.type = "image";
        } else {
          item.type = "other";
        }
      });
      data.productAttaches&&data.productAttaches.sort((a, b) => {
          return a.type === 'video' ? -1 : 1;
        });
     
      info.value = data
      setTimeout(()=>{
        carouselReady.value = true;
      },1)
    }
  })
}

onMounted(() => {
  productId.value = route.query.id;
  getInfoListmessageApi()
  checkTokenApi()
  productListRef.value?.getList()
})

const props = defineProps({
  // 子组件接收父组件传递过来的值
  isAll: Boolean,
  // 是否显示分类
  showTabs: {
    type: Boolean,
    default: true
  },
  title: String

})


</script>

<style lang="less" scoped>
@import "../assets/css/common.less";

::v-deep(.el-carousel__indicators) {
  display: none
}

::v-deep(.searchIcon) {
  .el-icon {
    color: var(--el-menu-search-icon-color) !important;
  }
}

.home-main {
  height: 100vh;
  width: 100vw;
  background: var(--page-bg);

  .home-right {
    margin-left: 20px;
    width: 490px;
  }

  .home-left {
    width: calc(100% - 510px);
  }

  .p_h_2 {
    ont-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #333;
    text-stroke: 1px transparent;
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px transparent;

  }

  .menu-line {
    border-bottom: 1px solid var(--el-menu-line-color);
  }

  .layout {
    ::v-deep(.collapseBtn) {
      color: var(--el-menu-search-arrow-icon-color);
    }

    ::v-deep(.collapseBtn:hover) {
      background: var(--el-menu-search-arrow-hover-bg-color) !important;
      color: var(--el-menu-search-arrow-icon-hover-color) !important;
      border-color: transparent !important;

      .el-icon {
        color: var(--el-menu-search-arrow-icon-hover-color) !important;
      }
    }

    ::v-deep(.collapseBtn.el-button, .collapseBtn.el-button) {
      color: var(--el-menu-search-arrow-icon-color);
      border-color: transparent !important;
      background-color: var(--el-menu-search-arrow-bg-color);
      outline: 0;
      margin-left: 0.375rem;
    }

    .searchIcon {
      ::v-deep(.el-input__prefix) {
        color: var(--el-menu-search-arrow-icon-color);
      }

      ::v-deep(input::-webkit-input-placeholder) {
        -webkit-text-fill-color: var(--el-menu-search-place-color);
      }

      ::v-deep(.el-input__wrapper) {
        .el-input__inner {
          color: var(--el-menu-search-active-text-color) !important;
          border: none;
          background: none !important;
          height: 32px;
        }

        background-color: var(--el-menu-search-active-bg-color);
      }

      ::v-deep(.el-input__wrapper.is-focus) {
        .el-input__inner {
          color: var(--el-menu-search-active-border-color) !important;
        }

        background-color: var(--el-menu-search-active-bg-color);
        border: 1px solid var(--el-menu-search-active-border-color);
      }

      ::v-deep(.el-input__wrapper) {
        background-color: var(--el-menu-search-bg-color);
        box-shadow: 0 0 0 1px var(--el-menu-search-border-color, var(--el-menu-search-border-color)) inset;
      }
    }
  }

  .danwei {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: left;
    font-style: normal;
    text-transform: none;

    .line {
      width: 1px;
      height: 30px;
      background: @border-color;
    }
  }

  .shortcut {
    display: inline-block;
    height: 20px;
    margin-right: 20px;
  }

  .p_box {
    .p1 {
      color: @text-color;
      font-size: 14px;
    }

    .p2 {
      color: @text-color;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei;
    }

    .p3 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
    }

    .p4 {
      font-family: Microsoft YaHei, Microsoft YaHei, serif;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
  }

  .tips {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #ff0000;
    border-radius: 50%;
  }
}

.bg-white {
  background: #fff;
}

.layout-main {
  background: var(--page-bg);
  padding: 0 20px;
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}
.border-radius-8 {
  border-radius: 8px 8px 0 0;
}
.menu-shadow {
  //box-shadow: 0px 0px 6px 1px rgba(3, 75, 143, 0.1608);
  border-right: 1px solid var(--menu-right);
}

.locateTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;

  .kefu-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('../assets/images/kefu1.png') no-repeat center center;
    background-size: 100% 100%;
  }

  h5 {
    color: #fff;
    font-weight: bold;
  }

  .user-num {
    color: #999999;
    font-size: 12px;
    font-weight: 400;

  }
}

::v-deep(.el-carousel__container) {
  height: 100% !important;
}

.no_detials_title_box {
  width: 100%;

  .video-wrapper {
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%; // 9:16 = 0.5625 = 56.25%
    }

    ::v-deep(.el-carousel) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    ::v-deep(.el-carousel__container) {
      height: 100%;
    }

    .carousel-content {
      width: 100%;
      height: 100%;
      position: relative;

      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
     
    }
  }

  .content-section {
    margin-top: 20px;
  }
}
::v-deep(.emptyPage) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
.p-master-2 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #020c33;
  text-stroke: 1px transparent;
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 1px transparent;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 限制在2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.introduction{
  font-size: 14px;
  font-weight: bold;
}
.text333{
  color: #333;
}
.text666{
  color: #666;
}
</style>
