<template>

  <div class="notice_details" :style="{ height: noToken ? '100vh' : '100%' }" :class="noToken ? 'p20' : ''">
    <div class="right bg-white">
      <div class="flex p20 justify-between items-center">
        <h5 class=""> {{ info.title }}</h5>
        <div class="star_icon" v-if="!isHome">
          <el-icon size="16" color="#B3B3B3" @click.stop="collectInfo(info.id)" v-if="info.collectFlag == '0'">
            <svg-icon icon-class="star"></svg-icon>
          </el-icon>
          <el-icon size="16" v-else>
            <svg-icon icon-class="star-active" @click.stop="collectInfo(info.id)" v-if="info.collectFlag == 1"></svg-icon>
          </el-icon>
        </div>
      </div>
      <div class="flex justify-between no_detials_title_box">
        <div class="flex flex-1 items-center flex-wrap">
          <el-tag v-for="i in info.infoCategoriesArr" class="mr-1 mb-1">{{ i }}</el-tag>
        </div>
        <div class="flex m_b_20 ">
          <el-icon style="margin-right: 5px;">
            <View />
          </el-icon>
          <span class="p_h_2 mr-3">{{ info.viewTimes || 0 }}</span>
          <p class="p_h_2 ">发布于：{{ getTimeAgoText(new Date(info.publishDate)) }}</p>
        </div>

      </div>
      <div class="right_bottom flex-1">
        <el-scrollbar height="100%">
          <div class="list ">

            <!-- <el-image style="width: 100%" :src="info.coverTemp" :fit="fit" /> -->
            <img :src="info.coverTemp" alt="" srcset="" style="width: 100%;">
            <div class="pt-4 subtitle">
              <p v-for="(item) in info.subtitle">{{ item }}</p>
            </div>
            <div class="pt-4">
              <p v-for="(item) in info.content"> {{ item }}</p>
            </div>

          </div>

          <div class="pagination">
            <div class="flex flex-wrap">
              <div class="w-1/2 flex-shrink-0 mb-2" v-for="(item, index) in info.infoAttaches"
                @click="imgDownload(item)">
                附件{{ index + 1 }}：
                <el-link type="primary">{{ item.fileName }}</el-link>
              </div>

            </div>
            <el-link type="primary" v-if="info.link" @click="go(info.link)">点击查看原文：{{ info.link }}</el-link>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, updatePersonUserInfo, getUserInfoByToken } from "@/api/UserInfo"
import { mapMutations, mapState } from "vuex";
import editInfo from "@/views/User/editPersonalInfo/component/editInfo.vue";
import { View } from "@element-plus/icons-vue";
import dicTag from "@/components/DicTag/index.vue"
import { collectInfoApi, getApi, noticeCount, postBlob } from "@/api";
import { downloadBlob, downloadget, getTimeAgoText } from "@/utils/tool";
export default {
  name: "userInfo",
  data() {
    return {
      info: {},
      isHome: false,
      noToken: true
    }
  },
  components: {
    editInfo, dicTag
  },
  methods: {
    getTimeAgoText,
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER"]),
    // 页面跳转
    jumpPage() {

      this.$router.push({
        path: this.info.noticeFunctionPath
      })
    },
    imgDownload(item) {
      let filename = item.fileName
      // downloadBlob(item.filePathTemp, item.fileName)
      postBlob("/api/file/ossFile/downloadStreamByPreSignedUrl", {
        preSignedUrl: item.filePathTemp,
        fileName: filename
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        if (res.headers['content-disposition']) {
          filename = decodeURI(res.headers['content-disposition'].split('filename=')[1]).replace(/"/g, '');
          if (filename === 'undefined') {
            // eslint-disable-next-line quotes
            filename = decodeURI(res.headers['content-disposition'].split("filename*=utf-8''")[1]).replace(
              /\\"/g,
              ''
            );
          }
        }
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        // downloadLoadingInstance.close()
      })
        .catch((r) => {
          // downloadLoadingInstance.close()
        })
    },
    go(url) {
      window.open(url)
    },
    // 收藏和取消收藏
    collectInfo(id) {
      let params = {
        infoId: id
      }
      collectInfoApi(params).then(res => {
        if (res.code === 200) {
          console.log(res)
          this.getInfo()
        }
      })
    },
    getInfo() {
      // 如果没有token 调用另一个接口
      if (sessionStorage.getItem("token")) {
        this.noToken = false
        // 根据 根据token获取用户名
        getApi(`/app/workbench/infoQuery/${this.$route.query.id}`, {}).then(res => {
          if (res.code === 200) {
            this.info = res.data
            this.info.infoCategoriesArr = res.data.infoCategories.map(i => i.categoryName)
            this.info.content = res.data.content.split('\n')
            this.info.subtitle = res.data.subtitle.split('\n')
          }
        })
      } else {
        this.noToken = true
        getApi(`/homepage/web/infoDetail`, {id:this.$route.query.id}).then(res => {
          if (res.code === 200) {
            this.info = res.data
            this.info.infoCategoriesArr = res.data.infoCategories.map(i => i.categoryName)
            this.info.content = res.data.content.split('\n')
            this.info.subtitle = res.data.subtitle.split('\n')
          }
        })
      }

    },
  },
  mounted() {
    this.getInfo()
    this.isHome = this.$route.query.home==1?true:false
  }
}
</script>

<style scoped lang="less">
.notice_details {
  margin: 0 auto;
  position: relative;
  width: 100%;

  .no_detials_title_box {
    padding: 0px 20px;
    border-bottom: 1px solid #E6E6E6;

    .p_h_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #020C33;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
    }

    .p_h_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }

    .no_detials_title_box_r {
      width: 200px;
    }
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right {
    width: 52%;
    height: calc(100% - 40px);
    // box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);
    border: 1px solid #E6EBF1;
    position: absolute;
    left: 50%;
    margin-left: -25%;
    border-radius: 8px;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .right_bottom {
      height: calc(100% - 130px);

      .subtitle {
        color: #8C8C8C;
      }
    }

    .list {
      padding: 20px;
      /*height: calc(100% - 330px); */
      overflow: hidden;

      p {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020C33;
        line-height: 24px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
      }

      .list_item {
        margin-bottom: 30px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020C33;
        line-height: 30px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
      }
    }

    .pagination {
      width: calc(100% - 40px);
      padding-left: 20px;
      // position: absolute;
      // bottom: 20px;
      // right: 20px;
    }
  }
}
</style>
